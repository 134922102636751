export interface Modules {
    title: string;
    listSubmodules: SubModules [];
    permissions: string [];
}
export interface SubModules {
    title: string;
    url: string;
    permissions: string [];
}

export enum envs {
    development = 'dev',
    test = 'test',
    prod = 'prod',
    default  = ''
}
export type envProps =  envs.development | envs.test | envs.prod | envs.default;


export interface UserModel {
    id: string;
    username: string;
    email: string;
    roles: string[];
    accessToken: string;
    tokenType: string;
    clave: string
}

export const initPerfil: UserModel = {
    id: '',
    username: '',
    email:'',
    roles: [],
    accessToken: '',
    tokenType: '',
    clave: ''
}

export enum languageOptions {
    spanish = 1,
    english = 2
}


export interface LanguageModel {
    idLenguaje: number;
    lang: string;
    descripcion: string;
    image?: string,
    checked?: boolean
}



