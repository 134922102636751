export const isoDatetoString = (isoDate: string): string => {
  const date = new Date(isoDate)

  const day = date.getDate() < 9 ? '0' + date.getDate() : date.getDate()
  const month =
    date.getMonth() + 1 < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const auxHour: number = date.getHours()
  const hours = auxHour < 13 ? auxHour : auxHour - 12
  const minutes =
    date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const hoursDesc = auxHour < 12 ? 'am' : 'pm'

  return (
    day +
    '/' +
    month +
    '/' +
    date.getFullYear() +
    ' a las ' +
    hours +
    ':' +
    minutes +
    ' ' +
    hoursDesc
  )
}
