import * as React from 'react'
import styles from './styles.module.css'
import {useEffect, useState} from 'react'
import {
    GridCol,
    GridRow,
    IconSearch,
    IconFacebook,
    IconInstagram,
    IconLinkedin, IconTwitter, IconYoutube,
    Link,
    IconClose
} from "@lmig/lmds-react";
import {flagMexico, flagUnitedStates, imageFooter, imageNav} from "./utils";
import {LocalStorageClass} from "./localStorageClass";
import { LanguageModel, Modules, languageOptions } from './types';
import * as apiTokenService from './services/ApiGral';
import { AxiosError } from 'axios';


export interface NavLFProps {
    env: 'dev' | 'test' | 'prod'
    roles: string[]
    returnLanguage: (language: any) => void
}


export const NavLF: React.FC<NavLFProps> = ({env, roles, returnLanguage}: NavLFProps) => {

    const ls = new LocalStorageClass()
  
    const [environment, setEnvironment] = useState<string>('')
    const [listModules, setListModules] = useState<Modules []>([])
    const [selectLanguage, setSelectLanguage] =  useState<number>()
        
    const [selectModule, setSelectModule] = useState<Modules>()
    const [visibleDropdown, setVisibleDropdown] = useState<boolean>(false)
    
    const listLanguages: LanguageModel[] = [
        {
            idLenguaje: 1,
            lang:'es',
            descripcion: 'Español',
            image: flagMexico,
            checked: false
        },
        {
            idLenguaje: 2,
            lang:'en',
            descripcion: 'English',
            image: flagUnitedStates,
            checked: false
        }
    ];

    let onLoaded = false;

    const validLanguage = () => {
        let localStorageLanguageSelect: LanguageModel = listLanguages[0];
        // en caso de no encontrar ningun lenguaje seleccionado en local storage hay que seleccionar por defaul español

        // si encontramos el lenguaje
        if (ls.getSomeString(ls.LANGUAGESELECT)){

            localStorageLanguageSelect = JSON.parse(ls.getSomeString(ls.LANGUAGESELECT));
            setSelectLanguage(localStorageLanguageSelect.idLenguaje);
            returnLanguage(localStorageLanguageSelect);
            console.log(selectLanguage)
        }
    }

    const validToken = async (token: string) => {
        try {
             await apiTokenService.getValidaToken(token, env);

        } catch (error) {
            const err = error as AxiosError
            if (err.response) {
                if (err.response.status == 401){
                    redirectLogin();
                }


            }
        }
    }


    const redirectLogin = () => {

        window.location.replace('/login');
        // if (env == 'dev') {
        //     window.location.replace('https://dev-serf.lmig.com/');
        // }
        // else if (env == 'test'){
        //     window.location.replace('https://test-serf.lmig.com/');
        // }
        // else if (env == "prod") {
        //     window.location.replace('/login');
        // }


    }

    const onClickItems = (index: number) => {
        if (visibleDropdown) {
            if (index != listModules.indexOf(selectModule!)) {
                setSelectModule(listModules[index]);
            } else {
                setVisibleDropdown(false);
            }

        } else {
            setVisibleDropdown(true);
            setSelectModule(listModules[index]);

        }

    }

    const isVisible = (permissions: string []) => {
        if (roles){
            // si solo esta como auditor
            if (permissions.find(permiso => permiso == ls.AUDITOR) && roles.length == 1) {
                return true;
            } else {
                // validamos todos los permisos con los roles
                for (const permiso of permissions) {
                    const find = roles.find(rol => rol == permiso)
                    if (find) {
                        return true;
                    }
                }
                return false;
            }
       
        }else {
            return false;
        }

    }

    const changeLanguage= () => {
        const selectLanguageAux = selectLanguage;
        const listLanguagesAux = listLanguages;
        
        if(selectLanguageAux == languageOptions.spanish){
            setSelectLanguage(listLanguagesAux[1].idLenguaje);
            ls.setSomeString(ls.LANGUAGESELECT,JSON.stringify(listLanguagesAux[1]));
            returnLanguage(listLanguagesAux[1]);
        }
        if(selectLanguageAux == languageOptions.english){
            setSelectLanguage(listLanguagesAux[0].idLenguaje);
            ls.setSomeString(ls.LANGUAGESELECT,JSON.stringify(listLanguagesAux[0]));
            returnLanguage(listLanguagesAux[0]);
        }
    }

    const closeSession = () => {
        localStorage.removeItem(ls.PERFIL);
        redirectLogin();
    }

    
    useEffect(() => {
        if(!onLoaded){
              //si no hay un token guardado
             if (!ls.getProfileToken()) {
                // redireccionar
                redirectLogin();

           }else {
                // si hay token hacer las acciones correspondientes
                // obtener los permisos especiales
                validToken(ls.getProfileToken());
                validLanguage();
            }
            const modules: Modules [] = [
                {
                    title: 'Compromisos',
                    permissions: [ls.EJECUTIVODEGARANTIAS, ls.FUNCIONARIO, ls.EJECUTIVOCUENTA, ls.AGENTE, ls.PROMOTOR, ls.BURO],
                    listSubmodules: [
                        {
                            title: 'Administración de Compromisos',
                            url: '/compromisos',
                            permissions:
                                [ls.EJECUTIVODEGARANTIAS,
                                    ls.FUNCIONARIO,
                                    ls.EJECUTIVOCUENTA,
                                    ls.AGENTE,
                                    ls.PROMOTOR,
                                    ls.BURO]

                        },
                        {
                            title: 'Cumplimiento de Compromisos',
                            url: '/compromisos/cumplimientos',
                            permissions:
                                [ls.EJECUTIVODEGARANTIAS,
                                    ls.EJECUTIVOCUENTA
                                ]
                        },
                        {
                            title: 'Autorización de Compromisos',
                            url: '/compromisos/autorizaciones',
                            permissions:
                                [ls.FUNCIONARIO,
                                    ls.EJECUTIVOCUENTA,
                                    ls.BURO
                                ]
                        }
                    ]
                },
                {
                    title: 'Garantías',
                    listSubmodules: [
                        {
                            title: 'Dashboard',
                            url: '/garantias/',
                            permissions: [ls.EJECUTIVODEGARANTIAS, ls.PROMOTOR, ls.EJECUTIVOCUENTA, ls.AUDITOR]
                        },
                        {
                            title: 'Revisión',
                            url: '/garantias/revision',
                            permissions: [ls.EJECUTIVODEGARANTIAS, ls.AUDITOR]
                        },
                        {
                            title: 'Reporte de Expediente',
                            url: '/garantias/reporte-expediente/',
                            permissions: [ls.EJECUTIVODEGARANTIAS, ls.AGENTE, ls.PROMOTOR, ls.AUDITOR]
                        }
                    ],
                    permissions: [ls.EJECUTIVODEGARANTIAS, ls.EJECUTIVOCUENTA, ls.AGENTE, ls.PROMOTOR, ls.AUDITOR]
                },
                {
                    title: 'Clientes',
                    permissions: [ls.AGENTE, ls.EJECUTIVOCUENTA, ls.PROMOTOR],
                    listSubmodules: [
                        {
                            title: 'Entevistas',
                            url: '',
                            permissions: [ls.AGENTE, ls.EJECUTIVOCUENTA, ls.PROMOTOR]
                        },
                        {
                            title: 'Contratos',
                            url: '',
                            permissions: [ls.AGENTE, ls.EJECUTIVOCUENTA, ls.PROMOTOR]
                        }
                    ]
                },
            ];
            setListModules(modules);
            setEnvironment(env);
            onLoaded = true;
        }
       
        console.log(roles)
    }, [env]);



    return <div>
        {(environment == 'dev' || environment == 'test') ?
            <div className={styles.nav}>
                <div className={styles.navBar}>
                    <div className={styles.flexContainer}>
                        <GridRow gutters>
                            <GridCol base={3} lg={2}
                                     className={styles.itemLeft}
                            >
                                <img className={styles.logoLiberty}
                                     src={imageNav}/>
                            </GridCol>

                            <GridCol base={9} lg={10}
                                     className={ styles.itemRight}>

                                <GridRow>
                                    <GridCol className={styles.optionsMenu} 
                                    sm={12} 
                                    md={10} 
                                    lg={10}>
                                     
                                     <GridRow>
                                            {(listModules.map((value, index) =>
                                                <GridCol
                                                    key={index}
                                                    onClick={() => {
                                                        onClickItems(index)
                                                    }}
                                                    style={{display: (isVisible(value.permissions)) ? 'block' : 'none'}}
                                                    base={2}
                                                    className={styles.items}>
                                                    <a>
                                                        {value.title}
                                                    </a>
                                                </GridCol>))

                                            }

                                        </GridRow>
                                    </GridCol>

                                    <GridCol className={styles.optionsIcons} sm={12} md={2} lg={2}>
                                        <GridRow gutters>

                                            <GridCol base={"flex-auto"}
                                                     className={styles.items + ' ' + styles.selectLanguage}>
                                                <a  className={styles.flags}
                                                    onClick={() => 
                                                    {
                                                        changeLanguage()
                                                    }
                                                    }>

                                                    {selectLanguage == languageOptions.spanish ? (
                                                        <img src={flagMexico} className={styles.imgCircle}   />
                                                    ): null}

                                                    {selectLanguage == languageOptions.english ? (
                                                        <img src={flagUnitedStates} className={styles.imgCircle} />
                                                    ): null}

                                                </a>

                                            </GridCol>
                                            <GridCol
                                                base={"flex-auto"}
                                                className={styles.items}>
                                                <a>
                                                    <IconSearch size="16"/> 
                                                </a>
                                            </GridCol>
                                            <GridCol
                                                base={"flex-auto"}
                                                className={styles.items}>
                                                    {(ls.PERFIL)?
                                                        <a onClick={()=> {
                                                            closeSession()
                                                        }}>
                                                            <IconClose size="16"/> 
                                                        </a>:
                                                        null

                                                    }
                                               
                                            </GridCol>
                                        </GridRow>
                                    </GridCol>
                                </GridRow>

                            </GridCol>
                        </GridRow>
                    </div>
                </div>
                {/* dropdown */}
                <div
                    style={{display: (visibleDropdown) ? 'block' : 'none'}}
                    className={styles.dropdownNav}>
                    <GridRow className={styles.dropdownContainer} gutters>
                        <GridCol base={2}>
                            <div className={styles.header}>
                                {selectModule?.title}
                            </div>
                            {selectModule?.listSubmodules.map((value, index) =>
                                <div
                                    style={{display: (isVisible(value.permissions)) ? 'block' : 'none'}}
                                    className={styles.subModules}>
                                    <Link key={index} href={value.url}
                                          variant="navigational">
                                        {value.title}<br/>
                                    </Link>
                                </div>
                            )}

                        </GridCol>
                        <GridCol base={2}>
                            <div>

                            </div>
                        </GridCol>
                        <GridCol base={2}>
                            <div>

                            </div>
                        </GridCol>
                        <GridCol base={2}>
                            <div>

                            </div>
                        </GridCol>
                        <GridCol base={2}>
                            <div>

                            </div>
                        </GridCol>
                    </GridRow>
                </div>
            </div> :
            null
        }

    </div>
}


export interface FooterProps {
    env: 'dev' | 'test' | 'prod'
}
export const FooterLF: React.FC<FooterProps> = ({env}) => {
    const [environment, setEnvironment] = useState<string>('');

    useEffect(() => {
            setEnvironment(env);
   
    }, [env]);


    return <div>

        
        {(environment == 'dev' || environment == 'test') ?
                <GridRow className={styles.footer} gutters>
                    <GridCol md={2}>
                        <GridRow>
                            <GridCol md={12}>
                                <img src={imageFooter}
                                     className={styles.imgFooter}/>
                            </GridCol>

                        </GridRow>
                    </GridCol>
                    <GridCol md={8} >
                        <GridRow gutters>
                            <GridCol md={4}>
                                <br/>
                                <hr/>
                            </GridCol>
                            <GridCol md={4}>
                                <div className={styles.iconsSocialNetworks}>
                                    <IconFacebook color={"inverse"} className={styles.iconsFooter} />&nbsp;
                                    <IconInstagram color={"inverse"}  className={styles.iconsFooter}/>&nbsp;
                                    <IconLinkedin color={"inverse"}  className={styles.iconsFooter}/>&nbsp;
                                    <IconTwitter color={"inverse"}  className={styles.iconsFooter}/>&nbsp;
                                    <IconYoutube color={"inverse"}  className={styles.iconsFooter}/>
                                </div>
                                <div className={styles.footerCorporate}>
                                    ©2024 Liberty Fianzas
                                </div>


                            </GridCol>
                            <GridCol md={4}>
                                <br/>
                                <hr/>
                            </GridCol>
                        </GridRow>

                    </GridCol>
                    <GridCol md={2}>
                    </GridCol>
                </GridRow>
            : null
        }



    </div>
}


