import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import { MensajeModel } from '../../models/MensajeModel'



export const getMensajesRevision = async (
  idProyecto: number,
  idReferencia: number,
  TOKEN: string,
  APIURL: string,
  APIKEY: string,
) => {
  const headers = {
    Accept: 'application/json',
    Authorization: TOKEN,
    'Content-Type': 'application/json',
    'X-API-KEY': APIKEY,
  }
  return await axios.get<MensajeModel[]>(
    APIURL +
      '/revision?idProyecto=' +
      idProyecto +
      '&idReferencia=' +
      idReferencia,
    {
      headers: headers
    }
  )
}

export const postNewMessage = async (
  newMessage: MensajeModel,
  TOKEN: string,
  APIURL: string,
  APIKEY: string,
) => {
  const headers = {
    Accept: 'application/json',
    Authorization: TOKEN,
    'Content-Type': 'application/json',
    'X-API-KEY': APIKEY
  }
  return await axios.post<MensajeModel>(APIURL + '/revision', newMessage, {
    headers: headers
  })
}
