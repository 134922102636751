import axios from 'axios'
import { UserModel } from '../types';
import { LocalStorageClass } from '../localStorageClass';

const ls = new LocalStorageClass()

const  headers = {
  Accept: 'application/json',
  'X-API-KEY': '',
  Authorization: ls.getProfileToken(),
  env: ''
}


export const getValidaToken= async (token: string, env: string) => {
  let API = ''
  if(env == 'dev'){
    API = 'https://api-dev.us.lmig.com/session'
    headers.env  = 'dev'
    headers['X-API-KEY'] = 'D7bUHts7uQaxH574RJVgT4V5Z4GBGEWRyZGKun3gfGYiltGG'
  }
  if(env == 'test'){
    API = 'https://api-tst.us.libertymutual.com/session'
    headers.env  = 'test'
    headers['X-API-KEY'] = 'HI3ElYqrUyfBXXJQ9gKEoWL0JneS7SKMXD54JiY882o8kDhC'
  }
  if(env == 'prod'){
    API = 'https://api.us.libertymutual.com/session'
    headers.env  = 'prod'
    headers['X-API-KEY'] = 'oM4Fr5NibElI0zuNgmAnNTDtO4frLZRCkGd8AQplGMIsLASw'
  }
  return await axios.get<UserModel>(API+'/validaToken?Valida=' + token , { headers: headers });
};
