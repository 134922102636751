import {UserModel, initPerfil} from "./types";


export class LocalStorageClass {

    // key Name
    public PERFIL = 'PERFIL';
    public LANGUAGESELECT = 'LANGUAGESELECT';

    // Garantias
    public EJECUTIVODEGARANTIAS = 'EJECUTIVO_GARANTIAS';
    public AGENTE = 'AGENTE';
    public AUDITOR = 'AUDITOR';
    //public AGENTE = 'AGENTE_COMPROMISOS';
    // Compromisos
    public EJECUTIVOCUENTA = 'EJECUTIVO_CUENTA';
    public FUNCIONARIO = 'FUNCIONARIO';
    public PROMOTOR = 'PROMOTOR';
    // buro de crádito
    public BURO = 'BURO';

    // Polizas RH
    public USUARIOCONTABLE = 'USUARIO_CONTABLE';
    //TESORERIA
    public CAJERO = 'CAJERO';

    //Juridico
    public AUXILIAR_JURIDICO = 'AUXILIAR_JURIDICO';

    constructor() {
    }

    public setSomeString(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    public getSomeString(key: string): string {
        return <string>localStorage.getItem(key);
    }

    public setSomeBoolean(key: string, value: boolean): void {
        localStorage.setItem(key,  value + '');
    }
    public  getSomeBoolean(key: string): boolean {
        if (localStorage.getItem(key) == 'true'){
            return true;
        }
        return  false;

    }

    public getProfile(): UserModel {
        let USER: UserModel = initPerfil;
        if (this.getSomeString(this.PERFIL)){
            USER = JSON.parse(this.getSomeString(this.PERFIL))
            return USER
        }else{
            return  initPerfil;
        }

    }


    public getProfileToken(): string {
        let USER: UserModel = initPerfil

        if (this.getSomeString(this.PERFIL)){
            USER = JSON.parse(this.getSomeString(this.PERFIL))

            if (USER.accessToken){
                return  USER.accessToken
            }else{
                return '';
            }

        }else{
            return  '';
        }
    }


}


// esta funcion busca en la lista de roles la cadena que le pases
export const validRoles = ( listRoles: string[], perfil: string): boolean  => {
    for (const roll of listRoles){
        if (roll== perfil){
            return  true;
        }
    }

    return false;
}